import React, { useState, useEffect } from 'react';
// import BackToTopButtom from '../components/BackToTopButtom';
import CleanPlace from '../assets/Images/CleanPlace.jpg';
import CleanHome from '../assets/Images/CleanHome.jpg';
import Logo from '../assets/Images/logo.png';
import Bathroom from '../assets/Images/bathroom.jpg';
import Clean from '../assets/Images/clean.jpg';
import Gutter_cleaning from '../assets/Images/gutter-cleaning.jpg';
import Gutter from '../assets/Images/gutter.jpg';
import Window from '../assets/Images/Window.jpg';
import Move_In from '../assets/Images/Move-In.jpg';
import Office from '../assets/Images/office.jpeg';
import Move_Out from '../assets/Images/Moving-Out.jpg';
import Driveway from '../assets/Images/driveway.jpg';
import Google from '../assets/Icons/google.png';
import Trusters from '../components/Trusters';
import FooterPage from '../components/FooterPage';
import { motion } from 'framer-motion'
import { UilCalling, UilCalendarAlt, UilFacebookF } from '@iconscout/react-unicons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Request from '../components/Request';
import CleaningForm from '../components/CleaningForm';
import ClipLoader from "react-spinners/ClipLoader";
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
Modal.setAppElement('#root')




const Home = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);


    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    // Set the Google Reviews URL based on whether the user is on a mobile device
    const googleReviewsUrl = isMobileDevice()
        ? `https://search.google.com/local/reviews?placeid=ChIJPQvSjqGtmlQRz6fzjvrwzwk`
        : `https://search.google.com/local/reviews?placeid=ChIJPQvSjqGtmlQRz6fzjvrwzwk`;

    const openGoogleReviews = () => {
        window.open(googleReviewsUrl, '_blank');
    };
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);

        const getReviews = async () => {
            try {
                const response = await fetch('https://us-central1-fabicleaners-28c5e.cloudfunctions.net/reviews');
                const data = await response.json();
                setReviews(data);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        getReviews();
    }, []);

    const CarouselItem = ({ src, alt, label }) => (
        <div className="relative">
            <img src={src} className="w-full h-96 sm:w-64 sm:h-96" alt={alt} />
            <p className="absolute bottom-0 bg-opacity-30 bg-black text-white font-light p-2">{label}</p>
        </div>
    );


    // Sample data for carousel items
    const carouselItems = [
        { src: Bathroom, alt: 'Bathroom', label: 'Bathroom Cleaning' },
        { src: Clean, alt: 'Home', label: 'Home Cleaning' },
        { src: Gutter, alt: 'Gutter', label: 'Gutter Cleaning' },
        { src: Window, alt: 'Window', label: 'Window Cleaning' },
        { src: Move_In, alt: 'Move In', label: 'Move In Cleaning' },
        { src: Move_Out, alt: 'Move Out', label: 'Move Out Cleaning' },
        { src: Office, alt: 'Office', label: 'Office Cleaning' },
        { src: Gutter_cleaning, alt: 'Gutter Cleaning', label: 'Gutter Cleaning' },
        { src: Driveway, alt: 'Driveway', label: 'Pressure Washing' },
    ];


    return (
        <div className="container mx-auto  bg-[#ffffff] overflow-hidden">
            <>
                <img src={CleanPlace} className='w-full h-96 ' alt='clean place ' />
                <h1 className="text-5xl font-bold text-center my-8 flex justify-center">Welcome</h1>
                <h2 className="text-center text-4xl text-[#057dcd] font-bold mb-3">Fabiana Fabulous Cleaning Services LLC</h2>
                <h5 className="text-center text-4xl text-[#057dcd] font-bold mb-3">Over 25 years of experience</h5>
                <p className="text-center text-xl mb-8 m-6 font-semibold first-letter:text-3xl p-5">
                    "Offers exclusive, high-end cleaning services tailored to luxury homes and estates. We bring unmatched attention to detail and elegance to every space we clean. Our commitment to perfection and premium care ensures that your home not only sparkles but embodies a pristine, sophisticated ambiance.<span className='text-3xl'>"</span>
                </p>
                <div className="flex justify-center mt-4 ">
                    <div >
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}>
                            <a href="tel:425-829-6092" className=" text-center text-lg text-[#000000] hover:text-[#ffc876] flex items-centertext-lg mb-8 h-12 px-4 py-3 border rounded-md bg-[#43b0f1] hover:bg-gradient-colors  ">
                                <UilCalling className="mr-2 call-animate" size="25" />  Call us today for a free estimate!
                            </a>
                        </motion.button>
                    </div>
                </div>
                <div className="md:flex md:mt-12 bg-gradient-color">
                    {/* Carousel Section */}
                    <div className="flex justify-center items-center md:w-1/2 sm:mr-5 ml-0 sm:ml-3">
                        <Carousel showArrows={false} autoPlay infiniteLoop transitionTime={1000} easing="ease" showThumbs={false} width={420}>
                            {carouselItems.map((item, index) => (
                                <CarouselItem key={index} src={item.src} alt={item.alt} label={item.label} />
                            ))}
                        </Carousel>
                    </div>

                    {/* Services List Section */}
                    <div className="flex md:justify-end md:mr-24 px-5 py-3 w-full mt-4 ml-5 md:w-1/2">
                        <ul className="text-lg text-[#000000]">
                            <li className="mb-2 text-2xl font-bold">What We Do!</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Home Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Deep Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Office Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Move In/Out Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Airbnb Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Gutter Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Window Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Roof Cleaning</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> Pressure Washing</li>
                            <li className="mb-2 text-xl font-semibold custom-li"> And More!</li>
                        </ul>
                    </div>
                </div>
                <h1 className="text-4xl bg-gradient-colors text-[#ffffff] font-serif font-bold text-center  px-16 py-12 bloom-text">Refer us and get 15% off on your next cleaning!</h1>
                <div className="relative">
                    {/* Background Image */}
                    <img src={CleanHome} className="w-full h-64 md:h-96" alt="clean home" />

                    {/* Dark Overlay */}
                    <div className="absolute inset-0 bg-black opacity-75"></div>

                    {/* Logo - Stacked on small screens */}
                    <img
                        src={Logo}
                        className="absolute top-8 left-24 transform -translate-x-1/2 md:top-1/2 md:left-1/4 md:-translate-y-1/2 w-40 h-40 md:w-64 md:h-64"
                        alt="Fabiana Fabulous Cleaning Services LLC"
                    />

                    {/* Slogan - Positioned below the logo on small screens */}
                    <h5 className="absolute top-12 left-2/3 transform -translate-x-1/3 text-[#d4af37] text-xl md:text-xl md:top-1/2 md:left-2/3 md:-translate-y-1/2 text-center">
                        WE MAKE YOUR HOME LOOK LIKE NEW AGAIN
                    </h5>

                    {/* Button - Positioned below the slogan on small screens */}
                    <div className="absolute top-48 left-1/2 transform -translate-x-1/2 md:top-2/3 md:left-2/3 md:-translate-y-1/2 flex items-center bg-[#43b0f1] hover:bg-gradient-colors px-4 py-2 rounded-md">
                        <button
                            onClick={openModal}
                            className="text-white hover:text-[#d4af37] flex items-center text-base md:text-xl"
                        >
                            <UilCalendarAlt className="mr-2 button-animate " size="18" /> Quick Estimate Form!
                        </button>
                    </div>

                    {/* Modal */}
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Cleaning Form"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                transition: 'opacity 0.5s',
                            },
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                maxWidth: '400px',
                                height: '80%',
                                maxHeight: '500px',
                                padding: '1.5rem',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                border: '1px solid #ccc',
                                overflow: 'hidden',
                                background: '#fff',
                                color: '#000',
                                zIndex: '1000',
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                            },
                        }}
                    >
                        <CleaningForm />
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-2xl text-black"
                            style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                        >
                            &times;
                        </button>
                    </Modal>
                </div>
                <div className="bg-[#edf2fa] w-full h-auto">
                    <h2 className="flex justify-center text-3xl font-bold p-10 text-center">What Our Clients Are Saying</h2>
                    <p className="flex justify-center text-2xl font-sans font-semibold tracking-wide m-10">Don’t take our word for it</p>

                    <div className="facebook-reviews-section p-4 bg-white rounded-lg shadow-xl mb-6">
                        <div className="flex justify-center">
                            <h2 className="text-3xl font-bold my-6" aria-label="Google Reviews">
                                <span className="google" style={{ marginRight: '9px' }}>
                                    <span className="blue">G</span>
                                    <span className="red">o</span>
                                    <span className="yellow">o</span>
                                    <span className="blue">g</span>
                                    <span className="green">l</span>
                                    <span className="red">e</span>
                                </span>
                                Reviews
                            </h2>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6 p-4">
                            {loading ? (
                                <ClipLoader color="#f86c6b" size={150} loading={loading} />
                            ) : (
                                reviews.sort((a, b) => b.time - a.time).map((review, index) => (
                                    <div
                                        key={index}
                                        className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-200"
                                        onClick={openGoogleReviews}
                                    >
                                        <div className="flex items-center mb-2">
                                            <img src={review.profile_photo_url || 'default-image-url'} alt={review.author_name} className="review-author-image w-10 h-10 rounded-full mr-2" />
                                            <div>
                                                <h2 className="review-author text-sm sm:text-base lg:text-lg font-bold">{review.author_name}</h2>
                                                <StarRatings
                                                    rating={review.rating}
                                                    starRatedColor="gold"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="20px"
                                                    starSpacing="2px"
                                                />
                                                <p className="review-text-time text-gray-600">{review.relative_time_description}</p>
                                            </div>
                                        </div>
                                        <p className="review-text text-gray-600">{review.text}</p>
                                        <span className="google my-5 p-2">
                                            <span className="blue">G</span>
                                            <span className="red">o</span>
                                            <span className="yellow">o</span>
                                            <span className="blue">g</span>
                                            <span className="green">l</span>
                                            <span className="red">e</span>
                                        </span>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="facebook-reviews-section p-4 bg-white rounded-lg shadow-lg">
                        <h2 className='text-3xl font-bold my-3 text-center flex justify-center'>
                            <span className='text-[#057dcd]'>Facebook</span> Reviews
                        </h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6 p-4">
                            <Carousel showArrows={true} showThumbs={false} showStatus={false}>
                                <div className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-200">
                                    <h2 className="review-author text-lg font-bold ml-2">Brianne Thompson</h2>
                                    <p className='review-text-time text-sm text-gray-500'>recommends Fabiána Fabulous Cleaning Services LLC.
                                        May 6, 2019
                                        ·
                                    </p>
                                    <p className="review-text text-gray-700 mt-2"><span className='text-3xl'>"</span>Just got a deep clean from Fabiana and her crew, and was thoroughly impressed. Very professional and detailed. This is not the sort of cleaning service where you get different cleaners every time- you get the same few cleaning professionals every time, which means they really know what your space requires. We look forward to having her return every two weeks!<span className='text-3xl'>"</span></p>
                                </div>
                                <div className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-100">
                                    <h2 className="review-author text-lg font-bold ml-2">Marilee Conrad Cipra</h2>
                                    <p className='review-text-time text-sm text-gray-500'>recommends Fabiána Fabulous Cleaning Services LLC.
                                        December 18, 2018
                                        ·
                                    </p>
                                    <p className="review-text text-gray-700 mt-2"><span className='text-3xl'>"</span>Fabiana is wonderful! The moment we met her we were sold based on her personality alone, not to mention that it’s clear she takes pride in providing excellent service. We just came home to a house cleaner than we’ve ever seen it. Seriously. We can’t stop calling out all the details she and her team covered. She’s communicative, friendly, and her work speaks for itself. Highly, highly recommend!<span className='text-3xl'>"</span></p>
                                </div>
                            </Carousel>
                            <Carousel showArrows={true} showThumbs={false} showStatus={false}>
                                <div className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-100">
                                    <h2 className="review-author text-lg font-bold ml-2">Leila Toigo</h2>
                                    <p className='review-text-time text-sm text-gray-500'>recommends Fabiána Fabulous Cleaning Services LLC.
                                        February 27
                                        ·
                                    </p>
                                    <p className="review-text text-gray-700 mt-2"><span className='text-3xl'>"</span>We have used Fabi for years and are so happy with her services. She's always thorough, on time, and fair with pricing. Fabi is a joy and we love her team too.<span className='text-3xl'>"</span>
                                    </p>
                                </div>
                                <div className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-100">
                                    <h2 className="review-author text-lg font-bold ml-2">Barbara Winslow Chinn</h2>
                                    <p className='review-text-time text-sm text-gray-500'>recommends Fabiána Fabulous Cleaning Services LLC.
                                        September 26, 2018
                                        ·
                                    </p>
                                    <p className="review-text text-gray-700 mt-2"><span className='text-3xl'>"</span>Fabiana does great work and pays attention to all the details. We have been using her company for years and have never been disappointed.<span className='text-3xl'>"</span></p>
                                </div>
                                <div className="review-card p-4 border rounded-lg shadow-md mb-4 flex flex-col bg-gradient-to-r from-blue-50 to-blue-100">
                                    <h2 className="review-author text-lg font-bold ml-2">Tanya Herman</h2>
                                    <p className='review-text-time text-sm text-gray-500'>recommends Fabiána Fabulous Cleaning Services LLC.
                                        August 16, 2018
                                        · </p>
                                    <p className="review-text text-gray-700 mt-2"><span className='text-3xl'>"</span>Faby is paying attention to all the small details. She is a perfectionist and leaves the house sparkling! She cleans everywhere, makes great suggestions, knows exactly which product to use for every surface. She is able to make a great effort to fit you into her tight schedule on a short notice if you have an urgent request. Will definitely recommend her to anyone!<span className='text-3xl'>"</span></p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                    <Trusters/>
                    <div className="flex justify-center mt-6">
                        <h2 className='text-3xl font-bold my-6'>Write Us A Review</h2>
                    </div>
                    <div className="flex justify-center">
                        <p className='text-xl font-sans text-center mb-4'>Help us improve our services by leaving feedback</p>
                    </div>
                    <div className='md:flex justify-evenly p-5'>
                        <div className="flex justify-center my-10">
                            <a
                                href="https://www.facebook.com/Fabulouscleaner/reviews"
                                target="_blank"
                                rel="noopener noreferrer"
                                title='Facebook  Review'
                                className="flex justify-center items-center text-lg h-10 px-10 py-5 border rounded-md bg-[#43b0f1] hover:bg-gradient-colors"
                            >
                                <UilFacebookF color="#1877F2" className="mr-3" /><p className='hover:text-[#d4af37]'>Write a Facebook Review</p>
                            </a>
                        </div>
                        <div className="flex justify-center my-10">
                            <a
                                href="https://search.google.com/local/writereview?placeid=ChIJPQvSjqGtmlQRz6fzjvrwzwk"
                                target="_blank"
                                rel="noopener noreferrer"
                                title='Google-Review'
                                className="flex justify-center items-center text-lg h-10 px-10 py-5 border rounded-md bg-[#43b0f1] hover:bg-gradient-colors"
                            >
                                <img src={Google} alt='google' className='w-10 h-10 mr-2' /><p className='hover:text-[#d4af37]'>Write a Google Review</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div id='request'>
                    <Request />
                </div>
                
                <div className="my-4">
                    <FooterPage />
                </div>
                {/* <div className='p-2 ml-8'>
                    <BackToTopButtom />
                </div> */}
            </>

        </div>
    );
};

export default Home;

