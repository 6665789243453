import React from 'react'
import Credibility from '../assets/Icons/credibility.png'
import CustomerReview from '../assets/Icons/customer-review.png'
import English from '../assets/Icons/english.png'
import Files from '../assets/Icons/files.png'
import Briefcase from '../assets/Icons/briefcase.png'

const Trusters = () => {
    return (
        <div className="bg-[#003366] text-white py-12 px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">FINDING TRUSTED CLEANERS FOR YOU.</h2>
            <h5 className="text-gray-300 mb-2">
                We know inviting someone into your home is a big deal. All FAbiana Fabulous Cleaning Services LLC cleaners are<br></br> carefully vetted by us so we choose the right person to care for your home.
            </h5>
            <h5 className="text-gray-300 mb-8">
                We guarantee your Fabiana Fabulous Cleaning Services LLC cleaner will always be:
            </h5>

            <div className="flex flex-wrap justify-center gap-8 mt-8">
                <div className="flex flex-col items-center max-w-[120px]">
                    <img src={Briefcase} alt='experienced img' className="w-16 h-16 text-[#d4af37]" />
                    <p className="text-[#d4af37] font-semibold mt-2">Experienced & professional</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img src={English} alt='english speacking img' className="w-16 h-16 text-[#d4af37]" />
                    <p className="text-[#d4af37] font-semibold mt-2">English speaking</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img src={Files} alt='reference img' className="w-16 h-16 text-[#d4af37]" />
                    <p className="text-[#d4af37] font-semibold mt-2">Background & reference checked</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img src={Credibility} alt='bonded img' className="w-16 h-16 text-[#d4af37]" />
                    <p className="text-[#d4af37] font-semibold mt-2">Licensed, bonded, and insured</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img src={CustomerReview} alt='highly rated img' className="w-16 h-16 text-[#d4af37]" />
                    <p className="text-[#d4af37] font-semibold mt-2">Highly rated by other customers</p>
                </div>
            </div>
        </div>
    )
}

export default Trusters
